<template>
  <div class="w-full pt-5 pb-5">
    <template>
      <div class="flex items-center">
        <div class="border-r border-romanSilver h-6 flex">
          <BackButton
            label="Back"
            @onClick="$router.back()"
            variant="secondary"
            class="ml-2"
          />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-4">
          Perf. Improvement {{ agreementData.title }}
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <div style="height: 100%" class="mt-20" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <template v-else>
      <div class="p-5 flex flex-col shadow m-5 rounded-md gap-4">
        <template>
          <div class="flex justify-between items-center">
            <span
              class="font-bold text-lg leading-5 tracking-normal capitalize text-darkPurple"
            >
              {{ agreementData.title }}
            </span>
          </div>
        </template>
        <template>
          <div
            class="border border-dashed bg-white border-romanSilver rounded-md gap-4 p-3 mt-4"
          >
            <div
              class="flex justify-between items-center w-full gap-4 pb-2 cursor-pointer"
              @click="handleShowDetails"
            >
              <span
                class="font-black text-xs leading-5 uppercase tracking-wider text-romanSilver"
              >
                Performance Details
              </span>
              <span
                class="w-10/12 border-t border-solid border-romanSilver h-0"
              />
              <Icon
                class-name="text-darkPurple mx-auto self-center"
                size="xms"
                :icon-name="showDetails ? 'chevronDown' : 'chevronRight'"
              />
            </div>
            <div class="flex flex-col gap-2" :class="{ hidden: !showDetails }">
              <div class="flex p-3 shadow rounded-md gap-2">
                <Icon
                  class-name="text-romanSilver self-start pt-1"
                  size="s"
                  icon-name="doc-cycle"
                />
                <div class="flex flex-col">
                  <span
                    class="font-black leading-5 text-xs tracking-wide text-darkPurple uppercase"
                  >
                    {{ agreementData.cycle }}
                  </span>
                  <span
                    class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                  >
                    {{ agreementData.description }}
                  </span>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div
                  class="flex p-3 shadow rounded-md gap-2 w-2/5 mr-2 mt-2 flex-grow"
                >
                  <Icon
                    class-name="text-romanSilver self-start pt-1"
                    size="xs"
                    icon-name="calendar"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-black leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                    >
                      Period
                    </span>
                    <span
                      class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                    >
                      {{
                        $DATEFORMAT(
                          new Date(agreementData.period.start),
                          "MMMM dd, yyyy"
                        )
                      }}
                      -
                      {{
                        $DATEFORMAT(
                          new Date(agreementData.period.end),
                          "MMMM dd, yyyy"
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="flex p-3 shadow rounded-md gap-2 w-2/5 mt-2 flex-grow"
                  v-for="(cycle, index) in agreementCycles"
                  :key="index"
                >
                  <Icon
                    class-name="text-romanSilver self-start pt-1"
                    size="xs"
                    icon-name="reviewicon"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-black leading-5 text-xs tracking-wide text-blueCrayola uppercase"
                    >
                      {{ cycle.name }}
                    </span>
                    <span
                      class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                    >
                      {{ $DATEFORMAT(new Date(cycle.start), "MMMM dd, yyyy") }}
                      -
                      {{ $DATEFORMAT(new Date(cycle.end), "MMMM dd, yyyy") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-if="!loading">
      <div class="py-0 px-5">
        <Table :headers="headers" :items="items">
          <template v-slot:item="{ item }">
            <div
              v-if="item.name"
              class="flex whitespace-nowrap font-semibold text-sm leading-5"
            >
              <img
                v-if="item.data.photo !== '' && item.data.photo !== null"
                class="mr-3 rounded"
                :src="item.data.photo"
                alt="profile"
                style="height: 36px; width: 36px"
              />
              <div
                style="height: 36px; width: 36px; border-radius: 5px"
                class="text-blueCrayola border text-center font-bold text-sm pt-2 mr-2"
                v-else
              >
                {{ $getInitials(`${item.data.name}`) }}
              </div>
              <span class="text-darkPurple capitalize pt-2">
                {{ item.data.name }}
              </span>
            </div>
            <div
              v-else-if="item.department"
              class="whitespace-nowrap font-semibold text-sm leading-5"
            >
              <span class="text-darkPurple capitalize">
                {{ item.data.department }}
              </span>
            </div>
            <div
              v-else-if="item.designation"
              class="whitespace-nowrap font-semibold text-sm leading-5"
            >
              <span class="text-jet capitalize">
                {{ item.data.designation }}
              </span>
            </div>
            <div v-else-if="item.manager">
              <span
                class="text-center text-sm leading-6 font-normal text-darkPurple"
              >
                {{ item.data.manager }}
              </span>
            </div>
            <div
              v-else-if="item.pipStartDate"
              class="capitalize text-sm font-normal leading-5 tracking-wider"
            >
              <span
                class="text-sm font-normal text-darkPurple"
                v-if="item.data.pipStartDate"
              >
                {{
                  $DATEFORMAT(new Date(item.data.pipStartDate), "MMMM dd, yyyy")
                }}
              </span>
              <span class="text-sm font-normal text-darkPurple" v-else
                >---</span
              >
            </div>
            <div
              v-else-if="item.duration"
              class="flex flex-col justify-start items-start"
            >
              <span class="text-sm leading-5 font-normal text-darkPurple">
                {{
                  $DATEFORMAT(
                    new Date(item.data.duration.startDate),
                    "MMMM dd, yyyy"
                  )
                }}
                -
              </span>
              <span class="text-sm leading-5 font-normal text-darkPurple">
                {{
                  $DATEFORMAT(
                    new Date(item.data.duration.endDate),
                    "MMMM dd, yyyy"
                  )
                }}
              </span>
            </div>
            <div v-if="item.id" class="cursor-pointer">
              <div
                @click="
                  $router.push({
                    name: 'ESSCloseOutDetails',
                    params: {
                      id: item.data.appraisee,
                      templateId: $route.params.id,
                    },
                    query: { pip: item.data.employeePip },
                  })
                "
              >
                <Icon icon-name="icon-eye" class="text-blueCrayola" size="xs" />
              </div>
            </div>
          </template>
        </Table>
      </div>
    </template>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";

export default {
  name: "ESSPerformanceImprovementDetails",
  components: {
    BackButton,
    Breadcrumb,
    Table,
  },
  data() {
    return {
      loading: true,
      showDetails: true,
      agreementData: {
        title: null,
        cycle: null,
        description: null,
        period: { start: null, end: null },
      },
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Improvement",
          href: "performance-improvement",
          id: "Performance Improvement",
        },
        {
          disabled: false,
          text: "",
          href: "performance-improvement-details",
          id: "PerformanceImprovementDetails",
        },
      ],
      agreementCycles: [],
      headers: [
        { title: "Appraisee", value: "name" },
        { title: "Function", value: "department" },
        { title: "Designation", value: "designation" },
        { title: "Line Manager", value: "manager" },
        { title: "PIP Start Date", value: "pipStartDate" },
        { title: "PIP Duration", value: "duration" },
        { title: "", value: "id", image: true },
      ],
      items: [],
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
    };
  },

  methods: {
    formatDate(value) {
      const startDate = `${value}/${new Date().getFullYear()}`;

      const [mm, dd, yyyy] = startDate.split("/");

      return new Date(`${dd}-${mm}-${yyyy}`).toISOString();
    },

    handleShowDetails() {
      this.showDetails = !this.showDetails;
    },

    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    getReports() {
      this.$_getEmployeeCloseOutDetails(this.$AuthUser.id).then((response) => {
        const resultData = response.data.employeeCloseouts;

        resultData.forEach((employee) => {
          this.items.push({
            id: employee.employeeCloseout[0].id,
            employeePip: employee.employeeCloseout[0].employeePipId,
            appraisee: employee.employeeCloseout[0].employee_pip.appraiseeId,
            name: `${employee.user.fname} ${employee.user.lname}`,
            photo: employee.user.photo,
            department: employee.user.function,
            designation: employee.user.designation,
            manager: `${employee.user.lineManager.fname} ${employee.user.lineManager.lname}`,
            pipStartDate: this.resetDate(
              employee.employeeCloseout[0].employee_pip.pipStartDate
            ),
            duration: {
              startDate: this.resetDate(
                employee.employeeCloseout[0].employee_pip.pipStartDate
              ),
              endDate: this.resetDate(
                employee.employeeCloseout[0].employee_pip.pipEndDate
              ),
            },
          });
        });
      });
    },

    getTemplate() {
      this.$_getPerfomanceTemplate('').then((response) => {
        const templatesArr = response.data.PerformanceTemplate;
        this.loading = false;

        this.templateArr = templatesArr;

        this.templateArr.filter((template) => {
          if (template.id === this.$route.params.id) {
            this.breadcrumbs[2].text = template.agreement_title;
            this.agreementData.title = template.agreement_title;
            this.agreementData.cycle = template.agreement_title;
            this.agreementData.description = template.agreement_description;
          }
          return {};
        });
      });
    },

    getAppraisalCycle() {
      this.$_getAppraisalCycle().then((response) => {
        const appraisal = response.data.AppraisalCycle;

        this.agreementData.period.start = this.formatDate(
          appraisal[0].cycle_start_date
        );
        this.agreementData.period.end = this.formatDate(
          appraisal[0].cycle_end_date
        );

        if (appraisal[0].cycles.length > 0) {
          appraisal[0].cycles.map((cycle) => {
            this.agreementCycles.push({
              name: cycle.name,
              start: this.formatDate(cycle.appraisal_starts),
              end: this.formatDate(cycle.appraisal_ends),
            });
            return {};
          });
        }
      });
    },
  },

  mounted() {
    this.getTemplate();
    this.getAppraisalCycle();
    this.getReports();
  },
};
</script>

<style scoped>
.submitted {
  background: rgba(19, 181, 106, 0.15);
  color: #27ae60;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
.not_submitted {
  background: rgba(234, 60, 83, 0.15);
  color: #ea3c53;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
</style>
